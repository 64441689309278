import {createTheme} from "@mui/material";

// interface MyPaletteOptions extends PaletteOptions {
//     dark?: Partial<PaletteOptions>;
// }

export const myThemeOptions = {
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        mode: "dark",
    }
};

export const createMyTheme = (options) => createTheme(options);