import React, {useEffect, useState} from 'react';

import {Box, Button, Card, CardContent, List, ListItem, ListItemText, TextField, Typography} from "@mui/material";

const Chat = () => {
    const [ws, setWs] = useState(null);
    const [inputText, setInputText] = useState('');

    useEffect(() => {
        // const wsScheme = window.location.protocol === "https:" ? "wss" : "ws";
        // const wsUrl = `${wsScheme}://${window.location.host}/services/chat`;
        // const socket = new WebSocket(wsUrl);
        //
        // socket.onopen = () => {
        //     setWs(socket);
        // };
        //
        // socket.onmessage = (event) => {
        //     console.log('Received message:', event.data);
        // };
        //
        // return () => {
        //     if (socket) {
        //         socket.close();
        //     }
        // };
    }, []);

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const handleSendMessage = () => {
        if (ws && inputText.trim() !== '') {
            ws.send(inputText.trim());
            setInputText('');
        }
    };

    return (
        <Box>
            <List>
                {/* Iterate over messages and render them */}
                <ListItem>
                    <ListItemText primary="Message content here"/>
                </ListItem>
                {/* Add more ListItems for additional messages */}
            </List>
            <Box>
                <TextField
                    label="Message"
                    value={inputText}
                    onChange={handleInputChange}
                />
                <Button onClick={handleSendMessage}>Send</Button>
            </Box>
        </Box>
    )
};

const MainCardContent = ({children}) => (
    <CardContent>
        {children}
    </CardContent>
);

export const ChatBox = () => (
    <Box>
        <Box>
            <Card sx={{minWidth: 275}}>
                <Typography variant="h4" gutterBottom>
                    Chat Box
                </Typography>
                <MainCardContent>
                    <Chat/>
                </MainCardContent>
            </Card>
        </Box>
    </Box>
);