import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import React from "react";

export const BlankHeader = () => (
    <AppBar
        elevation={0}
        position={"relative"}
        sx={{ // Add this line
            backgroundColor: 'transparent', // And this line
        }}
    >
        <Toolbar sx={{
            marginTop: '3px'
        }}>
        </Toolbar>
    </AppBar>
);